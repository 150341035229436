var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"u5jQWNLFhux-Sc7lCQexF"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { Identifier } from '@/state/identifier'
import { blindString } from './src/utils/blinders'

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';

Sentry.init({
  // todo make it configurable from upstream
  dsn: 'https://96108479e9b34900faf97da7783cfa44@o4506931753517056.ingest.us.sentry.io/4506932210302976',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  enabled: !isLocalhost,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Called for message and error events
  beforeSend(event) {
    const traverseAndScrubData = (obj: any, editFunctions: ((value: any) => any)[]) => {
      if (typeof obj !== 'object' || obj === null) return;
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          traverseAndScrubData(item, editFunctions);
        });
      } else {
        for (let key in obj) {
          editFunctions.forEach((editFunc) => {
            obj[key] = editFunc(obj[key]);
          });
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            traverseAndScrubData(obj[key], editFunctions);
          }
        }
      }
    }

    const identifierFn = (identifier: Identifier) => {
      let newIdentifier = identifier

      const arrayOfProperties: (keyof Identifier)[] = ['name', 'prefix']
      const isObjectIdentifier = arrayOfProperties.every((property: string) => identifier.hasOwnProperty(property))

      if (isObjectIdentifier) {
        newIdentifier = {
          ...identifier,
          name: blindString(identifier.name)
        }
      }

      return newIdentifier
    }

    traverseAndScrubData(event, [
      identifierFn,
    ])


    console.log('event', event)
    return event;
  },
});
